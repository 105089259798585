import state from "./moduleCardState.js";
import mutations from "./moduleCardMutations.js";
import actions from "./moduleCardActions.js";
import getters from "./moduleCardGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
