import axios from "@/axios.js";

export default {
  fetchConnectorByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/connectors/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  storeConnector({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/connectors", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateConnectorById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("admin/connectors/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeAdminConnector({ commit }, data) {

    return new Promise((resolve, reject) => {
      axios
        .post("admin/connectors/add", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAdminConnectorById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("admin/connectors/update/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteAdminConnectorById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("admin/connectors/delete/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAdminConnectorDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/connectors/details/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAdminConnectors({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/connectors/list"+params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllAdminConnectors({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/connectors/all")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
