import axios from "@/axios";

export default {
  setEmailSearchQuery({ commit }, query) {
    commit("SET_EMAIL_SEARCH_QUERY", query)
  },
  // fetch all email templates
  fetchAllSmsTemplates({ commit }, query) {
    return new Promise((resolve, reject) => {
      axios.get(`/admin/sms-templates?${query}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Fetch emails
  fetchEmails({ commit }, data) {
    ;
    return new Promise((resolve, reject) => {
      axios.get('/admins/emailTemplate?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          // commit("SET_MAILS", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

    // make sms template active
    makeSmsTemplateActive({ commit }, id) {

      return new Promise((resolve, reject) => {
        axios.put(`/admin/sms-templates/make-active/${id}`)
          .then((response) => {
            // commit("SET_MAILS", response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

  // fetch by id
  fetchSmsDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/admin/sms-templates/' + id).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

    // fetch default by slug productType and productName
    fetchDefaultSmsBySlug({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/admin/sms-templates/default/${data.slug}/${data.productType}`).then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      })
    },


  // create
  saveSmsTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/admin/sms-templates/create', data).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

  // update
  updateSmsDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put('/admin/sms-templates/update/' + data.id, data.info).then((res) => {
        resolve(res);
      }).catch(ex => {
        return reject(ex);
      });
    });
  },

  // Fetch Tags
  fetchTags({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/apps/email/tags")
        .then((response) => {
          commit("SET_TAGS", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Fetch Email Meta
  fetchMeta({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/apps/email/meta")
        .then((response) => {
          commit("SET_META", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Move mails to another folder
  moveMailsTo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/apps/email/move-mails", { emailIds: payload.emailIds, mailFolder: payload.to })
        .then((response) => {
          commit("MOVE_MAILS_TO", payload)
          commit("UPDATE_UNREAD_META_ON_MOVE", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Update Mails label
  updateLabels({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/apps/email/update-labels", { emailIds: payload.mails, label: payload.label })
        .then((response) => {
          commit("UPDATE_LABELS", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  setLabels({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/apps/email/set-labels", { mailId: payload.mailId, labels: payload.labels })
        .then((response) => {
          commit("SET_LABELS", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Set mails flag unread to true
  setUnread({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/apps/email/mark-unread", { emailIds: payload.emailIds, unreadFlag: payload.unreadFlag })
        .then((response) => {
          commit("SET_UNREAD", payload)

          // Remove this if you are getting meta like us
          // Use your own method to update email meta if you are fetching email meta
          commit("UPDATE_UNREAD_META", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Toggle isStarred flag in mail
  toggleIsMailStarred({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/apps/email/set-starred", { mailId: payload.mailId, value: payload.value })
        .then((response) => {
          commit("TOGGLE_IS_MAIL_STARRED", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
