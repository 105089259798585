import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt/index.js";
import httpStatusCode from 'http-status-codes';

export default {

  //dashboard
  fetchCardDetails({ commit }, data) {
    ;
    return new Promise((resolve, reject) => {
      axios.get('admin/dashboard/getCardDetails')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //dashboard
  fetchYearList({ commit }, data) {
    ;
    return new Promise((resolve, reject) => {
      axios.get('admin/dashboard/getYearList')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  //dashboard
  fetchChartsDetails({ commit }, year) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/dashboard/getChartDetails?year=${year}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .changePassword(
          data.userDetails.currentPassword,
          data.userDetails.newPassword
        )
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {

          reject({ message: response.data.message });
        });
    })
  },

  createAdminUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('admin/admin/auth', data)
        .then((res) => {
          resolve(res);
        }).catch(err => {
        reject(err.response)
      })
    })
  },

  fetchAllAdminUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/admin/auth" + params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },


  sendCredentialsToAdminUser({ commit }, id) {
    return new Promise((resolve, reject) => {

      axios
        .put(`/admin/admin/auth/sendCredentials/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateAdminUserDetailsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admin/admin/auth/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  deleteAdminUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/admin/admin/auth/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAdminUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('admin/admin/auth/'+id)
        .then((res) => {
          resolve(res);
        }).catch(err => {
        reject(err)
      })
    })
  },

  logoutAdmin({ commit }) {
    return new Promise((resolve, reject) => {
      jwt.logout()
        .then((response) => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    })
  },
  checkLogin({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('admin/admin/auth/check-login')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
