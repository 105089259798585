/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },

  SET_QUESTION_ANSWERS(state,questionAnswers){
    state.questionAnswers = questionAnswers;
  },

  ADD_QUESTION_ANSWERS(state,questionAnswer) {
    state.questionAnswers.unshift(questionAnswer);
  },

  UPDATE_QUESTION_ANSWERS(state, questionAnswer) {
    const qaIndex = state.questionAnswers.findIndex(f=>f._id == questionAnswer._id);
    Object.assign(state.questionAnswers[qaIndex], questionAnswer);
  },




}
