import axios from "../../http/axios/index.js";

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },
  SET_LEGALS(state, payload) {
    state.legals = payload;
  },

  UPDATE_LEGAL(state, payload) {
    // Find index
    const index = state.legals.findIndex(legal => legal._id === payload._id);

    if (index !== -1) {
      state.legals.splice(index, 1, payload);
    }
  },
};
