/*=========================================================================================
  File Name: moduleHistoryActions.js
  Description: Partner Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  fetchStaffByPartnerId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/staff-list/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchStaffDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/staff/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateStaffDetailsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/partners/staff/" + data.id, data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  createStaff({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("partners/staff", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteStaff({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/partners/staff/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPartners({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/partners" + params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchPartnerDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/partners/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  storePartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/partners", data.data, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  updatePartnerById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/admin/partners/" + data.id, data.data, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  deletePartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/admin/partners/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getThirdPartyToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/partners/getToken/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  sendCredentialsToMerchant({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("admin/partners/send-credentials/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
};

