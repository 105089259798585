import axios from "@/axios";

export default {
  fetchOnboardingRequestsList({ commit }, data) {
    const searchParam = data.searchParam;

    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchParam).toString();
      axios.get("admin/onboardingAdmin/onboardingRequestsList/?" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchOnboardDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("admin/onboardingAdmin/getdetail/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchOnboardReviewDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("admin/onboardingAdmin/getreviewdetail/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  saveSupportingFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("onboarding/saveSupportFile", payload.data, payload.config).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  statusUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("admin/onboardingAdmin/statusUpdate", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  sendIdVerificationRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("admin/onboardingAdmin/sendIdVerificationRequest", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  addBeneficiaryOwner({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/addOwner/${id}`, data).then((res) => {
          resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  addAuthorisedSignatory({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/addSignature/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  addBankAccount({ commit }, { id, data, config }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/addBank/${id}`, data, config).then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
    });
  },

  addPrimaryContact({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/addContact/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },


  deleteOnboardingForm({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("admin/onboardingAdmin/deleteOnboardingForm", payload).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  deleteBeneficiaryOwner({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/deleteOwner/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  deleteAuthorisedSignatory({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/deleteSignature/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  deleteBankAccount({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/deleteBank/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  deletePrimaryContact({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/deleteContact/${id}`, data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  sendOnboardingRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("admin/onboardingAdmin/onboardingRequest", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        return reject(ex);
      });
    });
  },

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("calculation", data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchTillWebhookList({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("admin/onboardingAdmin/getTillWebhookList" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  resolveNoMatchIssue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("admin/onboardingAdmin/resolveNoMatch", data).then(res => {
          resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  //update status
  linkIntroducerToMerchant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/onboardingAdmin/linkIntroducerToMerchant`, data).then((res) => {
        resolve(res);
      })
      .catch((ex) => {
        reject(ex);
      });
    });
  },

  checkMerchantEmailValidityAdmin({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/onboardingAdmin/is-valid-merchant-email/${email}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  createOnboardingProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("admin/onboardingProfile", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  getOnboardingProfileDetail({ commit }, profileId) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/onboardingProfile/${profileId}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  updateOnboardingProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`admin/onboardingProfile/${data.profileId}/update`, data.payload).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  saveGeneralInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/onboardingAdmin/saveGeneralInfo", data).then(res => {
        return resolve(res);
      })
      .catch(ex => {
        return reject(ex.response);
      });
    });
  },

  resendOnboardingLinkToMerchant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/onboardingAdmin/resendOnboardingLink", data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
  sendBackOnboard({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/onboardingAdmin/sendBack", data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  updateOnboardingUserInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`admin/onboardingAdmin/updateOnboardingInfo/${payload.param}`, payload.data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
}

