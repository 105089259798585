import axios from "@/axios.js";
export default {
  fetchSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`admin/setting/fetch/${payload}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  saveSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`admin/setting/save/${payload.settingName}`, payload).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
};


