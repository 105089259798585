import axios from "@/axios";

export default {
  saveFlipBankAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/flip-bank-accounts/create", data).then((res) => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchAllFlipBankAccounts({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("/admin/flip-bank-accounts").then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  updateFlipBankAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/admin/flip-bank-accounts/update/${data.id}`, data.info).then((res) => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  saveFlipTillConnectors({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/flip-bank-accounts/create", data).then((res) => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
}

