import axios from "../../http/axios/index.js";

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },
  SET_TOPICS(state, payload) {
    state.topics = payload;
  },
  DELETE_TOPIC(state, payload) {
    state.topics = state.topics.filter(item => item._id !== payload);
  },
  ADD_TOPIC(state, payload) {
    state.topics.push(payload);
  },
  UPDATE_TOPIC(state, payload) {
    // Find index
    const index = state.topics.findIndex(topic => topic._id === payload._id);

    if (index !== -1) {
      state.topics.splice(index, 1, payload);
    }
  },

};
