/*=========================================================================================
  File Name: moduleOnboardngProfile.js
  Description: OnboardingAdmin Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleOnboardingProfileState.js";
import mutations from "./moduleOnboardingProfileMutations.js";
import actions from "./moduleOnboardingProfileActions.js";
import getters from "./moduleOnboardingProfileGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
