import state from "./moduleCompanyState";
import mutations from "./moduleCompanyMutations";
import actions from "./moduleCompanyActions";
import getters from "./moduleCompanyGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
