import axios from "../../../axios/index.js";

const apiAdminUrl = "/admin/";

export default {
  login(payload) {
    return axios.post(apiAdminUrl + "auth/login", payload);
  },

  partnerLogin(email, pwd) {
    return axios.post("/auth/login", { email: email, password: pwd });
  },

  refreshToken() {
    return axios.post("auth/refreshToken", {
      refreshToken: JSON.stringify(localStorage.getItem("refreshToKen"))
    });
  },

  checkTokenExpiry(token) {
    return axios.post("checkTokenExpiry", { token });
  },

  resetPassword(password, token) {
    return axios.post("auth/resetPassword", {
      password: password,
      token: token
    });
  },

  resetAdminPassword(password, token) {
    return axios.post(apiAdminUrl + "auth/resetPassword", {
      password: password,
      token: token
    });
  },

  forgotPasswordAdmin(email) {
    return axios.post(apiAdminUrl + "auth/forgotPassword", { email: email });
  },

  resendVerificationMailAdmin(email) {
    return axios.post("auth/resendVerificationAdmin", { email: email });
  },

  changePassword(currentPassword, newPassword) {
    return axios.post(apiAdminUrl + "changeAdminPassword", {
      oldPassword: currentPassword,
      newPassword: newPassword
    });
  },

  checkResetTokenExpiry(token) {
    return axios.get(`auth/isResetTokenExpired?token=${token}`);
  },

  verifyMail(token) {
    return axios.get(`auth/verify/${token}`);
  },

  logout() {
    return axios.post(apiAdminUrl + "logout");
  },

  fetchUserByRememberMeToken(payload) {
    return axios.post(apiAdminUrl + "auth/fetchUserDetail", payload);
  },
};
