import axios from "@/axios";

export default {
  fetchOnboardingProfileForList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingProfile/for-dropdown")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getOnboardingProfiles({ commit }, data ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/onboardingProfile${data}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
