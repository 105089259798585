import state from "./moduleConnectorState";
import mutations from "./moduleConnectorMutations";
import actions from "./moduleConnectorActions";
import getters from "./moduleConnectorGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
