import axios from "@/axios";

export default {
  fetchAllPlans({ commit },params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/payment-plans${params}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAPlan({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/payment-plans/${params}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/payment-plans/", data)
        .then((res) => {
          commit("ADD_PLAN", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deletePlan({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("admin/payment-plans/" + id)
        .then((res) => {
          commit("DELETE_PLAN", id);
          return resolve(res);
        }).catch((ex) => {
          return reject(ex.response);
        });
    });
  },
  updatePlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("admin/payment-plans/" + data.id, data.data)
        .then((response) => {
          commit("UPDATE_PLAN", response.data.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProducts({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("admin/payment-plans/for-dropdown").then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },
};

