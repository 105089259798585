import axios from "@/axios";

export default {
  fetchList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/notification-verify/")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
