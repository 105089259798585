/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  getPaymentDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/payment/details?transactionId=" + id).then(res => {
        if (res.data.data) commit("SET_PAYMENT_PAGE_HEADER", res.data.data);
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  makePayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/make", data)
        .then(res => {
          return resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  getQuotationDataForPayLater({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post("/calculation", data).then(res => {
        return resolve(res);
      }).catch(err => {
        return reject(err.response);
      });
    });
  },
};
