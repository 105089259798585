/*=========================================================================================
  File Name: moduleAuth.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import mutations from './modulePaymentMutations.js'
import actions from './modulePaymentActions.js'
import getters from './modulePaymentGetters.js'

export default {
	namespaced: true,
    state: "",
    mutations: mutations,
    actions: actions,
    getters: getters
}
