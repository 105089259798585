/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js";

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },

  SET_PAYMENT_PAGE_HEADER(state, data) {
    state.page = {
      partnerName: data.paymentDetails.pageId.companyName,
      logo: data.paymentDetails.pageId.companyLogo,
      abn: data.paymentDetails.pageId.abn,
      address: data.paymentDetails.pageId.address,
      phoneNumber: data.paymentDetails.pageId.phoneNumber,
      email: data.paymentDetails.pageId.companyEmail,
      link: data.paymentDetails.pageId.link,
    };
  },
  SET_PAYMENT_PAGE_HEADER_FOR_STATIC(state, data) {
    state.page = {
      partnerName: data.companyName,
      logo: data.companyLogo,
      abn: data.abn,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.companyEmail,
      link: data.link,
    };
  }
};
