import axios from "@/axios.js";

export default {

  fetchData({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("admin/export").then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
};
