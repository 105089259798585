import axios from "@/axios.js";

export default {
  storePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests", data.obj, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  exportPaymentRequestListWithFilter({ commit }, data) {
    const searchparam = data.searchParam;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();
      axios
        .get("/admin/paymentRequest/export/?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  cancelPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admin/paymentRequest/cancel/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/paymentRequest/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  resendPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admin/paymentRequest/resend/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPaymentRequestListWithFilter({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(data).toString();
      axios
        .get("/admin/paymentRequest/?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // saveProductFieldInPr
  saveProductFieldInPr({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/paymentRequest/saveProductFieldInPr", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  registerBankTransferPost({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/paymentRequest/registerBankTransfer/${data.id}`, data.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  fetchMissedPayments({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/missedPayments?` + params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  exportMissedPayments({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/missedPayments/export?` + params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  approvePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`admin/paymentRequest/approvePaymentRequest/` + data.id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  declinePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`admin/paymentRequest/declinePaymentRequest/${data.id}`, data.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  sendCreditLodgementComms({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("admin/paymentRequest/sendCreditLodgementComms", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchIdVerficationData({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/paymentRequest/fetchIdVerificationData/${data._id}/${data.index}`)
        .then(res => {
          resolve(res);
        }).catch(ex => {
          return reject(ex);
        });
    });
  },

  updatePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/admin/paymentRequest/changePrStatus", data)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  changeDueDate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("/admin/paymentRequest/changeDueDate", data)
        .then(res => {
          resolve(res);
        }).catch(ex => {
          reject(ex);
        });
    });
  },

  gotoCustomerPortal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("customers/fetch-redirect-url", data).then(res => resolve(res)).catch(ex => reject(ex));
    });
  },

  updatePaymentRequestCommunication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/admin/paymentRequest/updatePrComms", data)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  saveDisbursementInPr({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/paymentRequest/saveDisbursementInPr", data)
        .then(res => {
          return resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },
};

