import axios from "@/axios";

export default {
  fetchDisbursementList({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(data).toString();
      axios
        .get("/admin/disbursements/?" + params)
        .then(res => {
          return resolve(res);
        }).catch(ex => {
          return reject(ex.response);
        });
    });
  },

  fetchDisbursementById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/disbursements/" + id)
        .then(res => {
          return resolve(res);
        }).catch(ex => {
          return reject(ex.response);
        });
    });
  },

  fetchMonoovaBalanceDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/disbursements/checkMonoovaBalance", data)
        .then(res => {
          return resolve(res);
        }).catch(ex => {
          return reject(ex.response);
        });
    });
  },

  processDisbursement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/disbursements/processDisbursement", data)
        .then(res => {
          return resolve(res);
        }).catch(ex => {
          return reject(ex.response);
        });
    });
  }
};

