import axios from "@/axios";

export default {
  fetchAllIntroducers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`/admin/introducers`+params)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // fetch by id
  fetchIntroducerDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/admin/introducers/' + id).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

  // save
  saveIntroducer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/admin/introducers/create', data).then((res) => {
        resolve(res);
      }).catch(err => {
        return reject(err.response);
      })
    })
  },

  // update
  updateIntroducer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put('/admin/introducers/update/' + data.id, data.info).then((res) => {
        resolve(res);
      }).catch(err => {
        return reject(err.response);
      })
    })
  },
}
