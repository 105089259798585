import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { auth } from "@/auth/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/admin/Dashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/view/payment-plan/:partnerId/",
          name: "payment-plan",
          component: () =>
            import("@/views/pages/admin/partners/PaymentPlan.vue"),
          meta: {
            rule: "editor",
            pageTitle: "Save payment plan",
          },
        },
        {
          path: "/paylaterplans/pay-later-product/create",
          name: "pay-later-product-select",
          component: () =>
            import("@/views/pages/admin/partners/PlanTypeSelect.vue"),
          meta: {
            rule: "editor",
            pageTitle: "Choose product type",
          },
        },
        {
          path: "/paylaterplans/pay-later-product/",
          name: "pay-later-product",
          component: () =>
            import("@/views/pages/admin/partners/PayLaterProduct.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Paylater products", url: "/paylaterplans" },
              { title: "Create", active: true },
            ],
            rule: "editor",
            pageTitle: "New Pay Later product",
          },

          beforeEnter: (to, from, next) => {
            const productType = to.query ? to.query.productType : ""
            if (to.query.id) {
              to.meta.pageTitle = `Edit ${productType.replace(
                "_",
                " "
              )} product`;
              to.meta.breadcrumb.find(
                (val) => val.active
              ).title = `Edit ${productType.replace(
                "_",
                " "
              )} product`;
            } else {
              to.meta.pageTitle = `New ${productType.replace(
                "_",
                " "
              )} product`;
              to.meta.breadcrumb.find(
                (val) => val.active
              ).title = `New ${productType.replace("_", " ")} Product`;
            }

            next();
          },
        },
        {
          path: "/paylaterplans/pay-later-product/legals/",
          name: "pay-later-legals",
          component: () => import("@/views/pages/admin/ProductLegalEditor"),
          meta: {
            rule: "editor",
            pageTitle: "Create Product Legals",
          },

          Enter: (to, from, next) => {
            to.meta.pageTitle =
              "New terms & conditions document - " +
              to.query.type.toLowerCase();

            next();
          },
        },
        {
          path: "/paylaterplans/pay-later-product/integration-guide",
          name: "integration-guide",
          component: () => import("@/views/pages/admin/ProductIntrgrationGuideEditor"),
          meta: {
            rule: "editor",
            pageTitle: "New integration guide article"
          },
          beforeEnter: (to, from, next) => {
            let backUrl = `/paylaterplans/pay-later-product`;

            if (to.query && to.query.productType) {
              backUrl = backUrl + `?productType=${to.query.productType}`;
            }

            if (to.query && to.query.id) {
              backUrl = backUrl + `&id=${to.query.id}`;
            }

            if (to.query.action == "edit") {
              to.meta.pageTitle = `Edit integration guide article`;
            }

            to.meta.breadcrumb = [
              { title: "Home", url: "/dashboard" },
              { title: "Products", url: "/paylaterplans" },
              { title: `${to.query.productType.replace("_", " ")} Product`, url: backUrl },
              { title: "integration guide", active: true },
            ],

            next();
          },
        },
        {
          path: "/paylaterplans",
          name: "pay-later-plan-list",
          component: () =>
            import("@/views/pages/admin/paymentplans/PaymentPlansList"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Paylater products", active: true },
            ],
            rule: "editor",
            pageTitle: "Paylater products",
          },
        },
        {
          path: "/users",
          name: "user-list",
          component: () => import("@/views/pages/admin/users/UserList"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Users", active: true },
            ],
            rule: "editor",
            pageTitle: "Users",
          },
        },
        {
          path: "/users/create",
          name: "user-create",
          component: () => import("@/views/pages/admin/users/UserForm"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Users", url: "/users" },
              { title: "New User", active: true },
            ],
            rule: "editor",
            pageTitle: "New User",
          },
          beforeEnter: (to, from, next) => {
            if (to.query.id) {
              to.meta.pageTitle = `Edit user`;
              if (to.meta.breadcrumb.length) {
                to.meta.breadcrumb[
                  to.meta.breadcrumb.length - 1
                ].title = `Edit user`;
              }
            }
            next();
          },
        },
        {
          path: "/partners/:id/edit",
          name: "partners-edit",
          component: () =>
            import("@/views/pages/admin/partners/PartnerForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchants", url: "/partners" },
              { title: "Merchant edit", active: true },
            ],
            pageTitle: "Merchant edit",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/partners",
          name: "partners-list",
          component: () =>
            import("@/views/pages/admin/partners/PartnerList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchants", active: true },
            ],
            pageTitle: "Merchants",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/partners/create",
          name: "partners-create",
          component: () =>
            import("@/views/pages/admin/partners/PartnerForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchants", url: "/partners" },
              { title: "Merchant create", active: true },
            ],
            pageTitle: "Merchant create",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/introducers",
          name: "introducers-list",
          component: () =>
            import("@/views/pages/admin/introducers/IntroducerList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Introducers", active: true },
            ],
            pageTitle: "Introducers",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/introducers/create",
          name: "introducers-create",
          component: () =>
            import("@/views/pages/admin/introducers/IntroducerForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Introducers", url: "/introducers" },
              { title: "Create", active: true },
            ],
            pageTitle: "Introducer create",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/introducers/:id/edit",
          name: "introducers-edit",
          component: () =>
            import("@/views/pages/admin/introducers/IntroducerForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Introducers", url: "/introducers" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit introducer",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/bank-accounts",
          name: "bank-accounts",
          component: () =>
            import("@/views/pages/admin/banks/BankaccountsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              {
                title: `Platform bank accounts`,
                active: true,
              },
            ],
            pageTitle: `Platform bank accounts`,
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-activity",
          name: "payment-activity",
          component: () =>
            import("@/views/pages/admin/payment-activity/paymentActivity"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Payment activity", active: true },
            ],
            pageTitle: "Payment Activity",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-requests/:id/show",
          name: "staff-payment-requests-detail",
          component: () => import("@/views/pages/admin/payment-activity/paymentRequestDetail"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Payment activity", url: "/payment-activity" },
              { title: "View payment request", active: true },
            ],
            pageTitle: "View payment request  ",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/disbursements",
          name: "disbursements",
          component: () =>
            import("@/views/pages/admin/disbursements/disbursementList"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Disbursements", active: true },
            ],
            pageTitle: "Disbursements",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/disbursements/:id",
          name: "disbursement-payload",
          component: () =>
            import("@/views/pages/admin/disbursements/disbursementPayload"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Disbursements", url: "/disbursements" },
              { title: "Payloads", active: true },
            ],
            pageTitle: "Payloads",
            requiresAuth: true,
            rule: "editor",
          },
          beforeEnter: (to, from, next) => {
            if (to.params.id) {
              to.meta.pageTitle = `Payloads ${to.params.id}`;
              if (to.meta.breadcrumb.length) {
                to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title = `Payloads ${to.params.id.toLowerCase()}`;
              }
            }
            next();
          },
        },
        {
          path: "/missed-payments",
          name: "missed-payments",
          component: () => import("@/views/pages/admin/missedPayments/missedPayments.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Missed payments", active: true },
            ],
            pageTitle: "Missed payments",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/cards",
          name: "card-list",
          component: () => import("@/views/pages/admin/cards/CardList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pay Now settings", active: true },
            ],
            pageTitle: "Pay Now settings",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/cards-legals-editor",
          name: "cards-legals-editor",
          component: () =>
            import("@/views/pages/admin/cards/CardLegalEditor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pay Now settings", url: "/cards" },
              { title: "Pay Now terms & conditions - ", active: true },
            ],
            pageTitle: "Pay Now terms & conditions - ",
            requiresAuth: true,
            rule: "editor",
          },
          beforeEnter: (to, from, next) => {
            if (to.query.user) {
              to.meta.pageTitle = `Pay Now terms & conditions -  ${to.query.user.toLowerCase()}`;
              if (to.meta.breadcrumb.length) {
                to.meta.breadcrumb[
                  to.meta.breadcrumb.length - 1
                ].title = `Pay Now terms & conditions -  ${to.query.user.toLowerCase()}`;
              }
            }
            next();
          },
        },

        {
          path: "/legals",
          name: "legals",
          component: () => import("@/views/pages/admin/legals/LegalList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Legals", active: true },
            ],
            pageTitle: "Legals",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/legals-editor/:type",
          name: "legals-editor",
          component: () => import("@/views/pages/admin/legals/LegalEditor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Legals", url: "/legals" },
              { title: "Create", active: true },
            ],
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
          },
          beforeEnter: (to, from, next) => {
            to.meta.pageTitle = `Create ${to.params.type
              .toLowerCase()
              .replaceAll("-", " ")}`;
            to.meta.breadcrumb[
              to.meta.breadcrumb.length - 1
            ].title = `Create ${to.params.type.toLowerCase()}`;

            next();
          },
        },
        {
          path: "/email-templates",
          name: "email-templates",
          component: () =>
            import(
              "@/views/pages/admin/email-templates/EmailTemplatesList.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Default communications templates", active: true },
            ],
            pageTitle: "Default communications templates",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/email-templates/create",
          name: "email-templates-create",
          component: () =>
            import("@/views/pages/admin/email-templates/EmailTemplateForm.vue"),
          meta: {
            pageTitle: "Create Email Template",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/email-templates/:id/edit",
          name: "email-templates-edit",
          component: () =>
            import("@/views/pages/admin/email-templates/EmailTemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              {
                title: "Default communications templates",
                url: "/email-templates",
              },
              { title: "Edit email template", active: true },
            ],
            pageTitle: "Edit email template",
            requiresAuth: true,
            rule: "editor",
          },
          beforeEnter: (to, from, next) => {
            if (to.query && to.query.planId && to.query.productType) {
              to.meta.breadcrumb[to.meta.breadcrumb.length - 2].title = "Product";
              to.meta.breadcrumb[to.meta.breadcrumb.length - 2].url = `/paylaterplans/pay-later-product?id=${to.query.planId}&productType=${to.query.productType}`;
            } else {
              to.meta.breadcrumb = [
                { title: "Home", url: "/dashboard" },
                {
                  title: "Default communications templates",
                  url: "/email-templates",
                },
                { title: "Edit email template", active: true },
              ];
            }

            next();
          },
        },

        {
          path: "/sms-templates/create",
          name: "sms-templates-create",
          component: () =>
            import("@/views/pages/admin/sms-templates/SmsTemplateForm.vue"),
          meta: {
            pageTitle: "Create SMS Template",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/sms-templates/:id/edit",
          name: "sms-templates-edit",
          component: () =>
            import("@/views/pages/admin/sms-templates/SmsTemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              {
                title: "Default communications templates",
                url: "/email-templates",
              },
              { title: "Edit SMS template", active: true },
            ],
            pageTitle: "Edit SMS template",
            requiresAuth: true,
            rule: "editor",
          },

          beforeEnter: (to, from, next) => {
            if (to.query && to.query.planId && to.query.productType) {
              to.meta.breadcrumb[to.meta.breadcrumb.length - 2].title = "Product";
              to.meta.breadcrumb[to.meta.breadcrumb.length - 2].url = `/paylaterplans/pay-later-product?id=${to.query.planId}&productType=${to.query.productType}`;
            } else {
              to.meta.breadcrumb = [
                { title: "Home", url: "/dashboard" },
                {
                  title: "Default communications templates",
                  url: "/email-templates",
                },
                { title: "Edit SMS template", active: true },
              ];
            }

            next();
          },
        },

        // Notification verify
        {
          path: "/notification-verify/",
          name: "notification-verify",
          component: () =>
            import("@/views/pages/notificationVerify/NotificationVerify.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Notification log", active: true },
            ],
            pageTitle: "Notification log",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/transactions",
          name: "staff-transactions",
          component: () =>
            import("@/views/pages/partners/transaction/Transaction.vue"),
          meta: {
            pageTitle: "Transactions",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/transactions/:id",
          name: "staff-transactions-details",
          component: () =>
            import("@/views/pages/partners/transaction/TransactionDetail.vue"),
          meta: {
            pageTitle: "Transaction",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/pages",
          name: "partner-pages",
          component: () => import("@/views/pages/partners/pages/ListPage.vue"),
          meta: {
            pageTitle: "Pages",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-profile",
          name: "onboarding-profile",
          component: () => import("@/views/onboardingAdmin/Profile"),
          meta: {
            pageTitle: "Onboarding profiles",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding profiles", active: true },
            ],
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-profile/create",
          name: "onboarding-profile-create",
          component: () => import("@/views/onboardingAdmin/Profile/create"),
          meta: {
            pageTitle: "New onboarding profile",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding profiles", url: "/onboarding-profile" },
              { title: "New onboarding profile", active: true },
            ],
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-profile/:onboardingProfileId/edit",
          name: "onboarding-profile-edit",
          component: () => import("@/views/onboardingAdmin/Profile/edit"),
          meta: {
            pageTitle: "Edit onboarding profile",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding profiles", url: "/onboarding-profile" },
              { title: "Edit onboarding profile", active: true },
            ],
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-admin",
          name: "onboarding-admin",
          component: () => import("@/views/onboardingAdmin/MerchantData"),
          meta: {
            pageTitle: "Onboarding requests",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding requests", active: true },
            ],
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-admin/create",
          name: "onboarding-request-create",
          component: () =>
            import(
              "@/views/onboardingAdmin/OnboardingRequest/OnboardingRequestForm"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding requests", url: "/onboarding-admin" },
              { title: "Send onboarding request", active: true },
            ],
            pageTitle: "Send onboarding request",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/till-webhook",
          name: "till-webhook",
          component: () => import("./views/onboardingAdmin/WebhookTill/index"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Till onboarding log", active: true },
            ],
            pageTitle: "Till onboarding log",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/data-export",
          name: "data-export",
          component: () => import("./views/pages/admin/exports/index"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Data exports", active: true },
            ],
            pageTitle: "Data exports",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/onboarding-admin/mvp-review/:onboardingId",
          name: "mvpReview",
          component: () => import("@/views/onboardingAdmin/ReviewData"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Onboarding requests", url: "/onboarding-admin" },
              { title: "Onboarding review", active: true },
            ],
            pageTitle: "Onboarding review",
            rule: "editor",
          },
        },
        {
          path: "/onboarding-review/:onboardingId",
          name: "onboardingReview",
          component: () => import("@/views/onboardingAdmin/ReadOnlyReview"),
          meta: {
            pageTitle: "",  // set dynamically in component
            rule: "editor",
          },
        },
        {
          path: "/view/profile",
          name: "admin-view-profile",
          component: () => import("@/views/pages/adminProfileNew.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Profile", active: true },
            ],
            rule: "editor",
            pageTitle: "Profile",
          },
        },

        {
          path: "/new-payment-platform",
          name: "new-payment-platform",
          component: () => import("@/views/pages/admin/directDebit/directDebitLegals.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "New payment platform settings", active: true },
            ],
            pageTitle: "New payment platform settings",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/new-payment-platform-legals-editor",
          name: "new-payment-platform-legals-editor",
          component: () => import("@/views/pages/admin/directDebit/directDebitLegalsEditor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "New payment platform settings", url: "/new-payment-platform" },
              { title: "Direct Debit terms & conditions - ", active: true },
            ],
            pageTitle: "Direct Debit terms & conditions - ",
            requiresAuth: true,
            rule: "editor",
          },
          beforeEnter: (to, from, next) => {
            if (to.query.user) {
              to.meta.pageTitle = `Direct Debit terms & conditions -  ${to.query.user.toLowerCase()}`;
              if (to.meta.breadcrumb.length) {
                to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title = `Direct Debit terms & conditions -  ${to.query.user.toLowerCase()}`;
              }
            }
            next();
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================

    {
      path: "",
      component: () => import("@/layouts/Terms/guest.vue"),
      children: [
        {
          path: "/terms-view/:id",
          name: "terms-view",
          component: () => import("@/views/pages/admin/partners/TermsView.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/paylaterplans/legals/:id",
          name: "pay-later-legal-view",
          component: () =>
              import("@/views/pages/admin/partners/ProductLegalsView.vue"),
          meta: {
            rule: "editor",
            pageTitle: "",
          },
        },
        {
          path: "/products/:id/borrower",
          name: "borrower",
          component: () => import("@/views/pages/admin/partners/ProductLegalsView.vue"),
          meta: {
            rule: "editor",
            pageTitle: "",
          },
        },
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/login",
          name: "admin-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },

        {
          path: "/forgotPassword",
          name: "forgot-admin-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            requiresAuth: false,
            rule: "editor",
          },
        },

        {
          path: "/resend-verification",
          name: "page-resend-verification",
          component: () => import("@/views/pages/ResendVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyMail", payload)
              .then(() => { return next(); })
              .catch((error) => {

                return next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/admin/reset-password",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/admin/reset-password/:token",
          name: "admin-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/terms-and-conditions/:slug",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/centralized-components",
          name: "CentralizedComponents",
          component: () => import("@/views/pages/centralizedComponents.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/design-system",
          name: "DesignSystem",
          component: () => import("@/views/pages/designSystem.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

// this method is calling in each router navigate
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    auth(to, from, next);
  } else {
    return next();
  }
});

export default router;
