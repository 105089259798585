import axios from "@/axios.js";
export default {
  
  addNote({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/note", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchNote({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/note/${payload}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
