import axios from "@/axios.js";

export default {
  fetchCompanyByParterId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("company/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
