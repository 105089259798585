/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_USERS(state, users) {
    state.users = users
  },
  REMOVE_RECORD(state, itemId) {
      const userIndex = state.users.findIndex((u) => u.id == itemId)
      state.users.splice(userIndex, 1)
  },
  UPDATE_ADMIN_PROFILE_IMAGE(state, payload) {
    let adminInfo = JSON.parse(localStorage.getItem("user"));
    if (payload) {
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          state.AppActiveUser[property] = payload[property];
          adminInfo[property] = payload[property];
        }
      }
    }
    localStorage.setItem("user", JSON.stringify(adminInfo));
  },
}
